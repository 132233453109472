<template>
  <div class="overall">
    <div class="content">
      <el-row>
        <el-col>
          <el-button type="primary" @click=handleAdd()>新增</el-button>
        </el-col>
      </el-row>
      <!-- table -->
      <div class="tableBox">
            <el-table
            :data="tableData"
                v-loading="tableLoading"
                row-key="id"
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                max-height="650"
                border
                ref="multipleTable"
               @selection-change="handleSelectionChange"
                style="width: 100%">
                <el-table-column
               type="selection"
               width="55">
               </el-table-column>
              <el-table-column
                prop="name"
                label="菜单名">
              </el-table-column>
              <el-table-column
                prop="url"
                label="路由">
              </el-table-column>
              <el-table-column
                prop="createAt"
                label="创建时间">
              </el-table-column>
              <el-table-column
                    label="操作"
                    width="200">
                    <template slot-scope="scope">
                        <el-button
                        @click.native.prevent="handleEdit(scope.$index, scope.row)"
                        type="primary">
                        编辑
                        </el-button>
                        <!-- <el-button
                        @click.native.prevent="handleDelete(scope.$index, scope.row)"
                        type="danger">
                        删除
                        </el-button> -->
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>

    <!-- 新增以及编辑弹框 -->
    <el-dialog
            :title="dialogTitle"
            :visible.sync="updateVisible"
            :before-close="beforeClose"
            width="30%">
            <el-form 
                ref="form"
                :model="form"
                :rules="rules"
                label-width="90px">
                <el-form-item label="菜单名称" prop="name">
                    <el-input placeholder="请输入菜单名称" v-model.trim="form.name"></el-input>
                </el-form-item>
                <el-form-item label="路由" prop="url">
                    <el-input placeholder="请输入路由" v-model.trim="form.url"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="beforeClose()">取 消</el-button>
                <el-button type="primary" @click="handleConfirm()">确 定</el-button>
            </span>
        </el-dialog>

  </div>
</template>
<script>
import{getList,remove,update,add} from '../../../api/menu'
export default {
  data(){
    return{
      currentPage: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      tableLoading: true,
      updateVisible: false,
      name:"",
      url:"",
      createAt:"",
      id:"",
      parentId:"",
      dialogTitle: '',
      ids:[],
      form:{
        name:"",
        url:"",
      },
       // 前台验证
       rules:{
        name: [
                  { required: true, message: '菜单名称不能为空', trigger: 'blur' },
              ],
        url: [
            { required: true, message: '路由不能为空', trigger: 'blur' },
        ],
    },
    }

  },
     // 1.页面初始化方法
  mounted(){
    this.menuList();
  },

  methods:{
    //列表
    async menuList(){
      try {
      this.tableLoading=true;
      let data = await getList();
      this.tableData = data;
      } catch (error) {
        console.log("error",error);
      }
      this.tableLoading=false;
    },

    //新增/编辑接口
    async menuUpdate(){
          try {
            //编辑
            if (this.dialogTitle ==="编辑") {
              let params = {
                id : this.itemId,
                name :this.form.name,
                url:this.form.url,
                parentId:this.form.parentId,
              }
              await update(params);
            }else{
                //新增
                let values = {

                }
              if(this.ids.length == 0){
                values={
                    ...this.form,
                    parentId:0
                  }
              }else{
                  values={
                      ...this.form,
                      parentId:this.ids[0]
                    }
              }
                await add(values);
            }
            this.$message({
                      showClose: true,
                      type: "success",
                      message: "操作成功!"
                });
            //表单数据置空，并移除校验
            this.$refs['form'].resetFields();
            this.menuList();
          } catch (error) {
            console.log("error",error);
          }
          this.updateVisible = false;
        },

    // 添加按钮
    handleAdd(){
        if (this.ids.length > 1) {
          this.$message.warning("只能选择一条数据");
        }else{
          this.dialogTitle ="新增";
          this.updateVisible =true;
        }
      },

      //新增按钮
      handleConfirm(){
          this.$refs['form'].validate((valid) => {
            if(valid){
              this.menuUpdate();
            }else{
              console.log("error submit!")
              return false;
            }
          });
        },
    
     // 编辑按钮
     handleEdit(index,rows){
        this.dialogTitle ="编辑";
        this.updateVisible =true;
        // 将选中这条数据的值赋值给编辑弹窗
        this.form = rows;
        this.itemId = rows.id;
      },

    //删除
    handleDelete(index,row) {
        let id = row.id
        this.$confirm("此操作将永久删除该数据, 是否继续?", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        }).then(() => {
            const params = {
            id
            };
            return remove(params);
        }).then(() => {
            this.menuList();
            this.$message({
                showClose: true,
                type: "success",
                message: "操作成功!"
            });
        }).catch(() => {
          this.$message({
          type: 'info',
          message: '已取消删除'
          });          
        });
    },

   //复选框
   handleSelectionChange(selectionList) {
            this.ids = [];
            selectionList.forEach(ele => {
            this.ids.push(ele.id);
        });
        return this.ids;
      },

    //新增/编辑页弹出框取消
    beforeClose() {
            this.updateVisible = false;
            //表单数据置空，并移除校验
            this.$refs['form'].resetFields();
        },
  }

}
</script>

<style lang="scss" scoped>
.overall {
    .content{
        .tableBox {
            padding-top: 20px;
        }
        .paginationBox {
            padding-top: 20px;
            text-align: center;
        }
    }
}
</style>